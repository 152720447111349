<script lang="ts">
import { store } from "../data/store";
import router from "@/router";
import Checks from "../shared/checks";
import LeadModel from "../shared/leadModel";
import EventTracking from "../shared/eventsTracking";
import Utils from "../shared/utils";
import LeadService from "../shared/leadService";

export default {
    name: "HomeView",
    components: {},
    data() {
        const lead: LeadModel = new LeadModel();
        const utils: Utils = new Utils();

        lead.name_landing = process.env.VUE_APP_NAME_LANDING;
        lead.uuid_landing = process.env.VUE_APP_UUID_LANDING;
        lead.uuid_user = utils.getUUID();

        const eventTracking: EventTracking = new EventTracking(
            process.env.VUE_APP_NAME_LANDING,
            process.env.VUE_APP_UUID_CUSTOMER,
            process.env.VUE_APP_UUID_LANDING
        );

        return {
            store: store,
            timer: 960,
            innerWidth: window.innerWidth,
            isBox1Selected: false,
            isBox2Selected: true,
            formName: "",
            formPhone: "",
            formAddress: "",
            product: "2_confezioni",
            errors: [] as string[],
            openFaqs: [],

            uuidCustomer: process.env.VUE_APP_UUID_CUSTOMER,
            uuidLanding: process.env.VUE_APP_UUID_LANDING,
            lead,
            eventTracking,
            firstname: "",
            lastname: "",
        };
    },
    methods: {
        handleResize() {
            this.innerWidth = window.innerWidth;
        },
        startTimer() {
            setInterval(() => {
                if (this.timer > 0) {
                    this.timer--;
                }
            }, 1000);
        },
        formatTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
                .toString()
                .padStart(2, "0")}`;
        },
        selectBox1() {
            this.isBox2Selected = false;
            this.product = "1_confezione";
        },
        selectBox2() {
            this.isBox1Selected = false;
            this.product = "2_confezioni";
        },
        toggleFaq(i) {
            if (this.openFaqs.includes(i)) {
                const indexOfi = this.openFaqs.indexOf(i);
                this.openFaqs.splice(indexOfi, 1);
            } else {
                this.openFaqs.push(i);
            }
        },
        getClasses() {
            if (
                this.innerWidth < 330 ||
                (this.innerWidth >= 880 && this.innerWidth < 1024) ||
                this.innerWidth >= 1388
            ) {
                return "hidden";
            }
        },
        async sendCustomEvent(eventName: string, eventValue: string) {
            console.log(eventName, eventValue);

            await this.eventTracking.trackEvent(
                eventName,
                "track",
                eventValue,
                true,
                false,
                null
            );
        },
        async validateForm() {
            const utils = new Utils();
            console.log("form inviato!");

            const checks = new Checks();
            let success = false;
            this.errors = [];

            if (!this.formName || !this.formPhone || !this.formAddress) {
                this.errors.push("Compila tutti i campi");
            }

            if (
                this.formName === "" ||
                this.formName.trim().split(" ").length < 2 ||
                !checks.isValidLength(this.formName) ||
                !checks.hasValidWordCount(this.formName)
            ) {
                this.errors.push("Inserisci nome e cognome");
            }

            this.firstname = this.formName.trim().split(" ")[0];
            this.lastname = this.formName.trim().split(this.firstname + " ")[1];

            if (
                !this.firstname ||
                this.firstname === "" ||
                !checks.isValidLength(this.firstname)
            ) {
                this.errors.push("Inserisci un nome");
            }

            if (!checks.checkPhone(this.formPhone)) {
                this.errors.push("Inserisci un numero di telefono valido");
            }

            if (
                !this.formAddress ||
                this.formAddress === "" ||
                this.formAddress.trim().split(" ").length < 3
            ) {
                this.errors.push("Inserisci un indirizzo di consegna valido");
            }

            if (!this.errors.length) {
                // Form is valid
                success = true;
                this.sendCustomEvent("naturaldente_form", null);

                const leadService = new LeadService(
                    this.uuidCustomer,
                    this.uuidLanding
                );
                this.lead.first_name = this.firstname;
                this.lead.last_name = this.lastname;
                this.lead.mobile_phone = this.formPhone;
                this.lead.address = this.formAddress;
                this.lead.product = this.product;

                console.log("Lead --> " + JSON.stringify(this.lead));
                await this.lead.initialize();
                await leadService.insertLead(this.lead);

                window.fbq(
                    "track",
                    "CompleteRegistration",
                    {},
                    { eventID: utils.getUUID() }
                );

                await this.eventTracking.trackEvent(
                    "CompleteRegistration",
                    "register",
                    "",
                    false,
                    true,
                    null,
                    null
                );

                this.sendCustomEvent("naturaldente_good_lead", null);

                router.push({ path: "/thank-you" });
            }

            return success;
        },
    },
    watch: {
        innerWidth(newWidth) {
            this.innerWidth = newWidth;
        },
    },
    async mounted() {
        await this.eventTracking.trackEvent(
            "naturaldente_view",
            "track",
            null,
            true,
            false,
            null,
            null
        );
        this.startTimer();
        window.addEventListener("resize", this.handleResize);
    },
};
</script>

<template>
    <header
        class="border bg-primary text-white text-xs md:text-sm lg:text-base"
    >
        <div class="flex justify-center items-center py-4">
            <p class="text-center">
                Consegna <strong>GRATIS in tutta Italia</strong>
            </p>
        </div>
    </header>

    <main>
        <!-- HERO -->
        <section
            id="hero"
            class="p-4 lg:p-8"
            :class="{ 'px-1.5': innerWidth < 350 }"
        >
            <!-- Brand Name -->
            <h3 class="text-blue text-3xl md:text-4xl font-bold">
                NaturalDente
            </h3>
            <p
                id="subtitle"
                class="text-blue text-sm md:text-base lg:text-lg font-light mt-0.5"
            >
                Il miglior amico del dente
            </p>

            <!-- Hero Image and Text -->
            <div
                id="hero-img"
                class="mt-10 flex flex-wrap items-center justify-center gap-4 md:gap-10 max-w-full"
            >
                <!-- Title -->
                <div>
                    <p id="hero-title" class="font-bold tracking-widest">
                        <span class="whitespace-nowrap">Trasforma il </span>
                        <br class="md:hidden" />
                        <span
                            class="underline decoration-yellow decoration-[6px] md:decoration-[10px] whitespace-nowrap"
                            >tuo sorriso</span
                        >
                        <br />
                        <span class="whitespace-nowrap">in soli </span>
                        <br class="md:hidden" />
                        <span
                            class="underline decoration-yellow decoration-[6px] md:decoration-[10px] whitespace-nowrap"
                            >5 giorni</span
                        >
                        <br />
                    </p>
                </div>

                <!-- Image -->
                <div
                    :class="{ 'mx-w-120': innerWidth < 375 }"
                    class="shrink max-w-[180px] md:max-w-[250px] lg:max-w-[300px] flex items-center justify-center py-3"
                >
                    <img
                        src="@/assets/NaturalDente.png"
                        alt="naturaldente-product"
                    />
                </div>

                <!-- Subtitle - No Mobile -->
                <p
                    :class="{ hidden: innerWidth < 430 }"
                    class="w-full flex justify-center gap-2 items-center leading-10 text-center lg:whitespace-nowrap"
                >
                    <span class="font-medium text-lg">1 confezione </span>
                    <span
                        class="text-white bg-yellow pt-2 pb-1.5 px-1.5 font-bold text-xl"
                        >GRATIS</span
                    >
                    <span class="text-sm font-medium"> se ordini entro </span>
                    <span class="text-sm font-medium">{{
                        formatTime(timer)
                    }}</span>
                    <span class="text-sm font-medium"> minuti</span>
                </p>
            </div>

            <!-- Subtitle - Mobile -->
            <p :class="{ hidden: innerWidth >= 430 }" class="text-center mt-6">
                <span class="font-xs text-sm">1 confezione </span>
                <span class="text-white bg-yellow py-1.5 px-1 font-bold text-sm"
                    >GRATIS</span
                >
                <span class="text-[10px] font-medium"> entro </span>
                <span class="text-[10px] font-medium">{{
                    formatTime(timer)
                }}</span>
                <span class="text-[10px] font-medium"> minuti</span>
            </p>
        </section>

        <!-- RESULTS -->
        <section id="results" class="bg-primary pt-5 pb-7 mt-6">
            <p class="text-center text-white text-xl md:text-2xl italic">
                In soli 5 giorni
            </p>
            <div
                :class="{ 'gap-3-imp': innerWidth < 340 }"
                class="flex flex-row flex-wrap items-center justify-center gap-[8%] md:gap-8 lg:gap-12 mt-4"
            >
                <img
                    src="@/assets/teeth-1.png"
                    class="w-[calc(100%/3-50px)] md:w-[calc(100%/3-50px)] lg:w-[calc(100%/4-50px)] min-w-[90px] max-w-[200px] md:max-w-[240px] results rounded-md"
                    alt="naturaldente"
                />
                <img
                    src="@/assets/teeth-2.png"
                    class="w-[calc(100%/3-50px)] md:w-[calc(100%/3-50px)] lg:w-[calc(100%/4-50px)] min-w-[90px] max-w-[200px] md:max-w-[240px] results rounded-md"
                    alt="smile"
                />
                <img
                    src="@/assets/teeth-3.png"
                    class="w-[calc(100%/3-50px)] md:w-[calc(100%/3-50px)] lg:w-[calc(100%/4-50px)] min-w-[90px] max-w-[200px] md:max-w-[240px] results rounded-md"
                    alt="teeth"
                />
            </div>
        </section>

        <!-- PROMO & DETAILS -->
        <section id="promo" class="pl-4 pr-2 py-8 md:px-[15%] lg:px-[28%]">
            <p class="text-center text-xl md:text-2xl">
                Sbiancante Naturale 5 GIORNI
            </p>

            <!-- Product Boxes -->
            <div class="flex flex-col items-center">
                <!-- Box 1 -->
                <label
                    class="border-2 rounded-md p-3 flex mt-6 min-h-[100px] max-w-[650px] cursor-pointer relative"
                    @click="selectBox1"
                >
                    <input
                        type="checkbox"
                        v-model="isBox1Selected"
                        class="absolute top-[-10px] left-[-10px]"
                    />
                    <div
                        class="w-[20%] bg-[#fcdbe8] flex justify-center items-center"
                    >
                        <img src="@/assets/prodotto.png" alt="product" />
                    </div>
                    <div class="w-[50%] flex items-center justify-center">
                        <span class="font-bold text-center">1 confezione</span>
                    </div>
                    <div
                        class="w-[30%] flex flex-col items-center justify-center md:gap-3"
                    >
                        <span
                            class="text-sm text-gray-400 line-through md:text-lg"
                            >39,90€</span
                        >
                        <span class="font-bold md:text-xl">29,90€</span>
                    </div>
                </label>

                <!-- Box 2 -->
                <label
                    class="border-2 rounded-md p-3 flex mt-8 min-h-[100px] max-w-[650px] cursor-pointer relative"
                    @click="selectBox2"
                >
                    <input
                        type="checkbox"
                        v-model="isBox2Selected"
                        class="absolute top-[-10px] left-[-10px]"
                    />

                    <!-- Responsive Box Text -->
                    <span
                        :class="{
                            hidden:
                                innerWidth < 880 ||
                                (innerWidth >= 1024 && innerWidth < 1388),
                        }"
                        class="bg-pink text-white text-center rounded-full px-2 py-1 text-[13px] absolute top-[-10px] translate-x-[-50%] left-[50%]"
                        >DISPONIBILE SE ORDINI ENTRO
                        {{ formatTime(timer) }} MINUTI</span
                    >
                    <span
                        :class="getClasses()"
                        class="bg-pink text-white text-center rounded-full px-2 py-1 text-xs absolute top-[-15px] translate-x-[-50%] left-[50%] w-max"
                        >VALIDO SOLO PER {{ formatTime(timer) }} MINUTI</span
                    >
                    <span
                        :class="{ hidden: innerWidth >= 330 }"
                        class="bg-pink text-white text-center rounded-full px-2 py-1 text-xs absolute top-[-15px] translate-x-[-50%] left-[50%] w-max"
                        >OFFERTA LIMITATA</span
                    >

                    <div
                        class="w-[20%] bg-[#fcdbe8] flex justify-center items-center"
                    >
                        <img src="@/assets/prodotti.png" alt="products" />
                    </div>
                    <div class="w-[50%] flex items-center justify-center px-3">
                        <span class="font-bold text-center"
                            >2 confezioni al prezzo di una</span
                        >
                    </div>
                    <div
                        class="w-[30%] flex flex-col items-center justify-center relative md:gap-3"
                    >
                        <span
                            class="text-sm text-gray-400 line-through md:text-lg"
                            >79,80€</span
                        >
                        <span class="font-bold md:text-xl">29,90€</span>
                        <span
                            class="bg-pink text-white text-center rounded-full px-2 tracking-wider text-sm absolute -bottom-2 lg:bottom-[-8px] 2xl:bottom-[5%]"
                            >-62%</span
                        >
                    </div>
                </label>
            </div>

            <!-- Reviews -->
            <div class="flex justify-center items-center mt-4">
                <img
                    src="@/assets/star.svg"
                    alt="stars"
                    class="mr-1.5 w-[70px] h-auto lg:w-[152px] lg:h-[32px]"
                />
                <span class="text-sm lg:text-lg self-end"
                    ><strong>4.9/5</strong> (1750+ Recensioni)</span
                >
            </div>

            <!-- Benefits -->
            <ul
                id="benefits"
                class="flex flex-col md:items-center gap-3 mt-6 md:text-lg"
            >
                <li class="text-blue flex gap-2">
                    <img src="@/assets/check.svg" alt="check" />
                    <strong>rimuove macchie e patina gialla.</strong>
                </li>
                <li class="text-blue flex gap-2">
                    <img src="@/assets/check.svg" alt="check" />
                    <span
                        ><strong>sbiancante naturale in polvere.</strong> Non
                        attacca lo smalto dei denti</span
                    >
                </li>
                <li class="text-blue flex gap-2">
                    <img src="@/assets/check.svg" alt="check" />
                    <strong>utilizzo rapido con lo spazzolino.</strong>
                </li>
            </ul>
        </section>

        <!-- FORM -->
        <section id="form-section" class="py-4 px-1 md:px-2">
            <h2 class="text-[19px] md:text-2xl text-center font-bold mb-2">
                Ordina ora e paga alla consegna
            </h2>
            <p
                v-show="isBox2Selected"
                class="text-[15px] md:text-lg text-center"
            >
                2 confezioni a 29,90€ invece che 79,80€
            </p>
            <p
                v-show="!isBox2Selected"
                class="text-[15px] md:text-lg text-center"
            >
                1 confezione a 29,90€ invece che 39,90€
            </p>

            <form
                class="flex flex-col gap-5 items-center w-[95%] md:w-[60%] mx-auto lg:w-[40%] max-w-[470px] my-6 py-2"
            >
                <!-- Name -->
                <input
                    type="text"
                    name="name"
                    id="name"
                    v-model="formName"
                    placeholder="Nome e Cognome"
                    required
                    class="bg-gray-200 px-2 md:px-4 py-4 w-[90%] md:w-[80%] text-center border-0 text-base"
                    :class="{
                        'border-red-500': errors.includes(
                            'Inserisci nome e cognome'
                        ),
                    }"
                />

                <!-- Phone -->
                <input
                    type="tel"
                    name="phone"
                    id="phone"
                    v-model="formPhone"
                    placeholder="Numero di telefono"
                    required
                    class="bg-gray-200 px-2 md:px-4 py-4 w-[90%] md:w-[80%] text-center border-0 text-base"
                    :class="{
                        'border-red-500': errors.includes(
                            'Inserisci un numero di telefono valido'
                        ),
                    }"
                />

                <!-- Address -->
                <input
                    type="text"
                    name="address"
                    id="address"
                    v-model="formAddress"
                    placeholder="Indirizzo di consegna (via, città, cap)"
                    required
                    class="bg-gray-200 px-2 md:px-4 py-4 w-[90%] md:w-[80%] text-center border-0 text-base"
                    :class="{
                        'border-red-500': errors.includes(
                            'Inserisci un indirizzo di consegna'
                        ),
                    }"
                />

                <!-- Error message -->
                <p
                    v-if="errors.length > 0"
                    class="text-red-500 rounded text-center text-sm mb-2"
                >
                    ⚠️ {{ errors[0] }}
                </p>

                <!-- Submit button -->
                <button type="button" class="btn" @click="validateForm()">
                    <img
                        src="@/assets/icon-btn.webp"
                        alt="icon-btn"
                        width="23"
                    />
                    <span>ORDINA ORA</span>
                    <img src="@/assets/arrow.svg" alt="arrow" width="18" />
                </button>

                <!-- Shipping Info -->
                <div
                    class="flex gap-1 justify-center items-start mt-[-5px] md:mt-[-10px] text-sm"
                >
                    <img src="@/assets/check-pink.svg" alt="check-icon" />
                    <span>SPEDIZIONE rapida + 1 barattolo omaggio</span>
                </div>
            </form>
        </section>

        <!-- Social Proof -->
        <section id="social-proof" class="px-2 py-3 md:py-6">
            <!-- Title -->
            <div
                class="mx-auto lg:w-[80%] flex flex-col lg:flex-row justify-center gap-4 md:gap-8 lg:gap-4 items-center"
            >
                <div
                    class="md:w-[65%] lg:w-[40%] px-4 pt-3 md:pt-6 lg:py-6 flex justify-center"
                >
                    <h2
                        class="text-white font-bold text-center text-2xl md:text-3xl md:min-w-[280px] w-full"
                    >
                        Rimuove macchie e patina gialla, protegge le gengive da
                        irritazione
                    </h2>
                </div>

                <!-- Boxes -->
                <div
                    id="social-boxes-container"
                    class="w-full md:w-[80%] flex justify-center gap-3 md:gap-8 pb-6 lg:py-6 px-4"
                >
                    <!-- Customers -->
                    <div
                        class="social-boxes bg-white rounded-md flex flex-col justify-between gap-6 items-center py-8 px-2 grow"
                    >
                        <img
                            src="@/assets/customers.svg"
                            alt="customers"
                            class="max-w-[70%]"
                        />
                        <h3 class="text-4xl">100,000+</h3>
                        <p
                            class="monospace text-gray-400 text-sm w-[177px] text-center"
                        >
                            Clienti
                        </p>
                    </div>

                    <!-- Satisfaction -->
                    <div
                        class="social-boxes bg-white rounded-md flex flex-col justify-between gap-6 items-center py-8 px-2 grow"
                    >
                        <img
                            src="@/assets/satisfaction.svg"
                            alt="customers"
                            class="max-w-[70%]"
                        />
                        <h3 class="text-4xl">1,750+</h3>
                        <p
                            class="monospace text-gray-400 text-sm w-[177px] text-center"
                        >
                            Recensioni a 5 stelle
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Results -->
        <section
            class="bg-[#ffdcea] pt-12 pb-8 text-center flex flex-col md:flex-row items-center justify-center gap-5 md:gap-8"
        >
            <img
                src="@/assets/NaturalDente.png"
                alt="product-img"
                class="w-[50%] max-w-[250px] md:max-w-[280px]"
            />
            <div class="text-center px-2">
                <p class="font-bold text-lg md:text-xl lg:text-2xl">
                    Un solo utilizzo al giorno.
                </p>
                <p
                    class="font-bold text-lg md:text-xl lg:text-2xl text-pink mt-1"
                >
                    Risultati visibili in 5 giorni.
                </p>
                <div
                    class="flex flex-col items-center justify-beetween gap-3 mt-8"
                >
                    <!-- Shipping Info -->
                    <div
                        :class="{ hidden: innerWidth <= 335 }"
                        class="text-xs md:text-sm"
                    >
                        <p>
                            🎁 Affrettati - 1 confezione omaggio se ordini entro
                            <span class="text-pink font-bold font-normal">{{
                                formatTime(timer)
                            }}</span>
                        </p>
                    </div>

                    <div
                        :class="{ hidden: innerWidth > 335 }"
                        class="text-xs md:text-sm"
                    >
                        <p>
                            🎁 1 confezione omaggio entro
                            <span class="text-pink font-bold font-normal">{{
                                formatTime(timer)
                            }}</span>
                        </p>
                    </div>

                    <!-- Button -->
                    <a
                        href="#form-section"
                        class="btn flex flex-col items-center justify-center w-[90%] md:w-auto max-w-[300px] md:max-w-[430px] px-4"
                    >
                        <span class="hidden md:block md:whitespace-nowrap"
                            >ORDINA ORA, PAGA ALLA CONSEGNA</span
                        >
                        <span class="md:hidden">ORDINA ORA</span>
                        <span class="md:hidden text-sm mt-[-8px]"
                            >e paga alla consegna</span
                        >
                    </a>
                </div>
            </div>
        </section>

        <!-- Reviews + CTA -->
        <section
            class="px-4 py-6 md:pt-12 flex flex-col md:flex-row-reverse items-center justify-center gap-4 md:gap-7 lg:gap-12"
        >
            <div>
                <div
                    class="flex justify-center items-center mt-4 mb-1.5 md:mb-3"
                >
                    <img
                        src="@/assets/star.svg"
                        alt="stars"
                        class="mr-1.5 w-[70px] h-auto md:w-[85px] lg:w-[152px] lg:h-[32px]"
                    />
                    <span
                        :class="{ hidden: innerWidth <= 350 }"
                        class="text-sm lg:text-lg self-end"
                        ><strong>4.9/5</strong> (1750+ Recensioni da 5
                        stelle)</span
                    >
                    <span
                        :class="{ hidden: innerWidth > 350 }"
                        class="text-xs self-end"
                        ><strong>4.9/5</strong> (1750+ Recensioni 5
                        stelle)</span
                    >
                </div>

                <h3 class="text-center text-xl md:text-2xl font-bold pb-6">
                    Le recensioni parlano per noi.
                </h3>
            </div>

            <div>
                <!-- Button -->
                <a
                    href="#form-section"
                    class="btn w-[95%] md:w-[60%] mx-auto lg:w-[40%] max-w-[470px]"
                >
                    <img
                        src="@/assets/icon-btn.webp"
                        alt="icon-btn"
                        width="23"
                    />
                    <span>ORDINA ORA</span>
                    <img src="@/assets/arrow.svg" alt="arrow" width="18" />
                </a>

                <!-- Shipping Info -->
                <div
                    class="flex gap-1 justify-center items-start mt-[10px] text-sm"
                >
                    <img src="@/assets/check-pink.svg" alt="check-icon" />
                    <span
                        :class="{ hidden: innerWidth <= 410 }"
                        class="text-center"
                        >Spedizione rapida
                        <span class="font-medium">GRATIS</span> + pagamento alla
                        consegna</span
                    >
                    <span
                        :class="{ hidden: innerWidth > 410 }"
                        class="text-center text-xs"
                        >Spedizione GRATIS, pagamento alla consegna</span
                    >
                </div>
            </div>
        </section>

        <!-- FAQ -->
        <section class="pt-8 pb-20">
            <div class="max-w-[1200px] mx-auto flex flex-col items-center">
                <div
                    class="w-[800px] py-7 cursor-pointer border-b-2 border-black faq"
                >
                    <h2
                        class="text-center text-black text-[28px] md:text-4xl font-semibold"
                    >
                        Domande Frequenti
                    </h2>
                </div>
                <template v-for="(item, i) in store.faqItems" :key="i">
                    <div
                        @click="toggleFaq(i)"
                        class="faq w-[800px] border-b-2 border-black px-6 py-7 flex flex-col gap-8 md:gap-6 cursor-pointer"
                    >
                        <div class="flex justify-between gap-2 items-center">
                            <p class="md:text-lg">{{ item.title }}</p>
                            <img
                                src="@/assets/chevron.png"
                                alt="arrow-icon"
                                width="24"
                                height="24"
                                class="chevron-down"
                                :class="{
                                    'rotate-180': this.openFaqs.includes(i),
                                }"
                            />
                        </div>
                        <div v-show="openFaqs.includes(i)" class="mb-2">
                            <p class="text-[#2B1A1A] text-sm">
                                {{ item.text }}
                            </p>
                        </div>
                    </div>
                </template>
            </div>
        </section>
    </main>
</template>

<style scoped>
@media screen and (min-width: 300px) {
    .mx-w-120 {
        max-width: 120px !important;
    }
}

@media screen and (max-width: 300px) {
    #hero {
        padding: 0.75rem;

        & h3 {
            font-size: 1.3rem;
        }

        & #subtitle {
            font-size: 0.8rem;
            margin-top: 0.2rem;
            line-height: 1rem;
        }

        & #hero-img {
            & > div {
                width: 100%;
            }

            & img {
                max-height: 160px;
            }

            & > div:first-child {
                display: flex;
                justify-content: center;
                text-align: center !important;
                padding-bottom: 1rem;
            }
        }
    }

    #social-proof h2 {
        font-size: 1.3rem;
    }

    #social-boxes-container {
        flex-direction: column;
    }
}

@media screen and (max-width: 400px) {
    #hero-img {
        gap: 0;
    }

    #social-proof .social-boxes {
        gap: 10px;

        & img {
            max-width: 50px;
        }

        & h3 {
            font-size: 1.6rem;
        }

        & p {
            font-size: 0.75rem;
            max-width: 100px;
        }
    }
}

@media screen and (max-width: 850px) {
    .faq {
        width: 90%;
    }
}

#hero-title {
    font-size: clamp(1.3rem, 0.8099rem + 1.6901vw, 5rem);
    line-height: calc(1.8rem + 1.6901vw);

    @media screen and (min-width: 768px) {
        line-height: calc(3rem + 1.6901vw);
    }
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    appearance: none;
    border: 1px solid black;
    border-radius: 50%;
    outline: none;
    background-color: #f2f2f2;
}

input[type="checkbox"]:checked {
    background-color: #f2f2f2;
}

input[type="checkbox"]:checked::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: #ff2178;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.btn {
    background-color: #33d670;
    color: white;
    text-align: center;
    border-radius: 0.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    width: 100%;
    letter-spacing: 0.05em;
    font-size: 1.25rem;
    line-height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.btn:hover {
    background-color: #2dba61;
}

#form-section h2,
#form-section p {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
}

#promo label:hover {
    background-color: rgb(242, 242, 242);
}

#social-proof {
    background-image: linear-gradient(
        90deg,
        #ff2277ff 0%,
        #724ce9ff 98.01980198019803%
    );
    background-size: 100%;

    & h3 {
        background-image: linear-gradient(
            90deg,
            #ff2277ff 0%,
            #724ce9ff 98.01980198019803%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.faq p {
    font-family: "Noto Serif", serif;
}

.chevron-down {
    transition: transform 0.6s;
}

.gap-3-imp {
    gap: 0.75rem !important;
}
</style>
