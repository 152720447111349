import { reactive } from 'vue'

export const store = reactive({
    faqItems: [
        { title: 'Che ingredienti contiene lo sbiancante in polvere?', text: 'Il nostro sbiancante naturale è formulato con ingredienti 100% naturali, garantendo efficacia senza danneggiare lo smalto dei denti.'},
        { title: 'Come si usa lo sbiancante in polvere?', text: 'Semplicemente applica la polvere sullo spazzolino umido e lavati i denti come faresti normalmente, due volte al giorno per 5 giorni.'},
        { title: 'Questo sbiancante danneggerà il mio smalto dentale?', text: 'No, la formula delicata è progettata per essere efficace contro le macchie e la patina gialla senza attaccare lo smalto.'},
        { title: 'È sicuro per le gengive sensibili?', text: 'Sì, oltre a sbiancare i denti, il nostro prodotto aiuta a combattere l\'infiammazione gengivale, rendendolo adatto anche per chi ha gengive sensibili.'},
        { title: 'Quanto dura l\'effetto dello sbiancante?', text: 'Gli effetti possono variare, ma con un uso regolare e un\'adeguata igiene orale, puoi mantenere il tuo sorriso brillante a lungo.'},
        { title: 'Posso usare lo sbiancante con apparecchi ortodontici o corone?', text: 'Raccomandiamo di consultare il tuo dentista prima di usare il nostro prodotto su apparecchi ortodontici o corone per assicurarti che sia sicuro.'},
        { title: 'Quali sono le opzioni di pagamento disponibili?', text: 'Offriamo il pagamento alla consegna per rendere il tuo acquisto il più semplice e sicuro possibile.'},
        { title: 'Come funziona la spedizione gratuita?', text: 'La spedizione è gratuita su tutto il territorio italiano, con consegna rapida direttamente a casa tua.'}
    ]
});