<script lang="ts">
import { RouterLink, RouterView } from "vue-router";
import Utils from "./shared/utils";
import Footer from "./components/Footer.vue";
import EventsTracking from "./shared/eventsTracking";

// Estendi l'interfaccia Window per includere fbq
declare global {
    interface Window {
        fbq: (...args: any[]) => void;
    }
}

export default {
    name: "App",
    components: {
        Footer,
    },
    data() {
        const eventTracking: EventsTracking = new EventsTracking(
            process.env.VUE_APP_NAME_LANDING,
            process.env.VUE_APP_UUID_CUSTOMER,
            process.env.VUE_APP_UUID_LANDING
        );

        return {
            eventTracking,
        };
    },
    created() {
        const utils = new Utils();
        utils.setUUID();
    },
    mounted() {
        const utils = new Utils();

        window.fbq("init", "1032443788405542", {
            external_id: utils.getUUID(),
            country: "it",
        });
        window.fbq("track", "PageView", {}, { eventID: utils.getUUID() });

        this.eventTracking.trackEvent(
            "PageView",
            "track",
            "",
            false,
            true,
            null,
            null
        );
    },
};
</script>

<template>
    <RouterView />
    <Footer />
</template>

<style scoped></style>
