import ParamsModel from "./paramsModel";

class LeadModel extends ParamsModel {
    first_name: string;
    last_name: string;
    mobile_phone: string;
    indirizzo: string;
    is_visible: boolean = true;
    product: string;
    name_landing: string;
    uuid_landing: string;
    uuid_customer: string;
    uuid_user: string;
    fbp: string = this.utils.getCookie("_fbp");
    fbc: string = this.utils.getCookie("_fbc");
    force_save: boolean = false;
    ip_address: string = "";

    constructor() {
        super();
    }

    async initialize() {
        this.ip_address = await this.fetchIpAddress();
    }

    private async fetchIpAddress(): Promise<string> {
        try {
            const response = await fetch("https://api.ipify.org?format=json");
            const data = await response.json();
            return data.ip;
        } catch (error) {
            console.error("Failed to fetch IP address", error);
            return "";
        }
    }
}

export default LeadModel;
