import axios from "axios";
import LeadModel from "./leadModel";

class LeadService {
    private uuid_landing: string;
    private uuid_customer: string;
    private code_state: number = 200;

    /**
     *
     */
    constructor(uuid_customer: string, uuid_landing: string) {
        this.uuid_customer = uuid_customer;
        this.uuid_landing = uuid_landing;
    }

    async insertLead(lead: LeadModel): Promise<number> {
        try {
            const response = await axios.post(
                "https://trk.everset.dev/leads/insertuser/" +
                    this.uuid_customer +
                    "/" +
                    this.uuid_landing,
                lead,
                {
                    headers: {
                        token: "jpa%rg(Qj5%#!p+w5NkeL2ACNH*TtD",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );

            if (response.data.message === "Phone Number validation failed") {
                this.code_state = 403;
            }
        } catch (error) {
            if (
                error.response.data.message === "Phone Number validation failed"
            ) {
                this.code_state = 403;
            }
            console.log(error);
        }

        // .catch(async function (err) {
        //     if (
        //         err.response.data.message ===
        //         "Phone Number validation failed"
        //     ) {
        //         errors.push("Please enter a valid mobile number");
        //         document.getElementById("error").innerHTML =
        //             "Please enter a valid mobile number";
        //         number.style.borderColor = "red";
        //         // internal Tracking dynamicTrackingEvent( event, action, event_name)
        //         await dynamicTrackingEvent(
        //             "novus-quiz-en hlr-fail",
        //             "load",
        //             "calculator",
        //             "calculator",
        //             "0a306251-b0e7-49cd-ba5e-8653cc67bb09"
        //         );
        //     } else if (err.response.data.message === "User Exist") {
        //         document.getElementById("form").style.display = "none";
        //         document.getElementById("title").style.display = "none";
        //         document.getElementById("thankyou").style.display = "block";
        //     } else {
        //         console.log(err);
        //         alert("Error");
        //     }
        // });

        return this.code_state;
    }
}

export default LeadService;
