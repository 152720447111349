import "./assets/style.scss";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import Futura from './fonts/FuturaRenner-Light.otf'
// import Futura from "./assets/fonts/FuturaRenner-Regular.otf";
// import Albra from "./assets/fonts/AlbraDisplayTRIAL-Regular.otf";

const app = createApp(App);

app.use(router);

app.mount("#app");
